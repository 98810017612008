/** @jsx jsx */

import { jsx } from 'theme-ui'

import TableList from '../components/table-list'
import routes from '../constants/routes'

export const halo = {
  title: `Investor application documents`,
  route: routes.investorApplicationDocuments,
}

export const intro = {
  ...halo,
}

const lists = [
  {
    title: `Investor application documents`,
    items: [
      {
        href: routes.investorApplicationDocumentsCcmxLegalEntityInvestorApplicationForm,
        title: `CCMX Legal Entity Investor Application Form`,
      },
      {
        href: routes.investorApplicationDocumentsSection2OfInvestorApplicationRollupAccounts,
        title: `Section 2 of Investor Application - Rollup Accounts`,
      },
      {
        href: routes.investorApplicationDocumentsSection3OfInvestorApplicationLegalEntities,
        title: `Section 3 of Investor Application - Legal Entities`,
      },
      {
        href: routes.investorApplicationDocumentsSection4OfInvestorApplicationAuthorizedPersons,
        title: `Section 4 of Investor Application - Authorized Persons`,
      },
      {
        href: routes.investorApplicationDocumentsSection5OfInvestorApplicationIndividualControlPersonsAndEntityOwners,
        title: `Section 5 of Investor Application - Individual Control Persons and Entity Owners`,
      },
      {
        href: routes.investorApplicationDocumentsSection6OfInvestorApplicationReadOnlyAccountUsers,
        title: `Section 6 of Investor Application - Read Only Account Users`,
      },
      {
        href: routes.investorApplicationDocumentsSuitabilityAttestation,
        title: `Suitability Attestation`,
      },
      {
        href: routes.investorApplicationDocumentsBrokerageAccountAgreement,
        title: `Brokerage Account Agreement`,
      },
      {
        href: routes.investorApplicationDocumentsCertificateOfAuthority,
        title: `Certificate of Authority`,
      },
      {
        href: routes.investorApplicationDocumentsIndividualAccreditedInvestorQuestionnaire,
        title: `Individual Accredited Investor Questionnaire`,
      },
      {
        href: routes.investorApplicationDocumentsThirdPartyAgentAuthorization,
        title: `Third Party Agent Authorization`,
      },
      {
        href: routes.investorApplicationDocumentsQibQuestionnaire,
        title: `QIB Questionnaire`,
      },
    ],
  },
]

export const content = (
  <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, pt: 5, pb: 6, px: 4 }}>
    {lists.map((list, index) => (
      <TableList key={list.title} containerStyles={index && { mt: 5 }} {...list} />
    ))}
  </div>
)
